@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .primary-button {
    @apply bg-button text-white rounded-lg p-2 dark:bg-dark-button dark:text-white hover:brightness-125 hover:cursor-pointer flex flex-row gap-2;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

    & span {
      @apply text-white;
    }

    svg {
      @apply text-white;
    }

    img {
      @apply text-white;
    }

    &:active {}

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }


  .secondary-button {
    @apply text-header disabled:text-gray-500;
    background-color: #333;
    border-radius: 4px;
    padding-inline: 15px;
    font-size: 16px;
    transition:
      background-color 0.3s ease,
      transform 0.3s ease,
      box-shadow 0.3s ease;

    &:hover {
      background-color: #555;
    }

    &:active {
      background-color: #111;
      transform: scale(0.98);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 212, 47, 0.5);
    }

    &:disabled {
      background-color: #ccc;
      color: #666;
    }
  }

  // .secondary-button svg {
  //   @apply text-header disabled:text-gray-500;

  // }

  // &:disabled {
  //   background-color: #ccc;
  //   color: #666;
  //   fill:#666;
  // }
  .secondary-button img {
    @apply text-white;

    &:disabled {
      background-color: #ccc;
      color: #666;
    }
  }

  // .secondary-button.active {}

  // .secondary-button.disabled {
  //   @apply opacity-50 pointer-events-none;
  // }



  .delete-button {
    @apply bg-red-500 text-white rounded-md py-2 px-3 hover:brightness-125 hover:cursor-pointer flex flex-row gap-2;

    & span {
      @apply text-white;
    }
  }

  .delete-button svg {
    @apply text-white;
  }

  .delete-button img {
    @apply text-white;
  }




  .sidebar-item {
    @apply bg-sidebar text-header p-2 rounded-md hover:bg-header hover:text-white dark:bg-dark-sidebar dark:hover:brightness-125;

    svg {
      @apply text-white hover:text-white dark:text-white shadow-md;
    }

    .sidebar-text {
      @apply text-header dark:text-white;
    }
  }

  .sidebar-item.icon {
    @apply flex items-center justify-center;
  }

  .sidebar-item.active {
    @apply bg-header dark:bg-dark-header;

    svg {
      @apply text-white;
    }

    .sidebar-text {
      @apply text-black;
    }
  }


}

.app-modal {
  .mat-mdc-dialog-surface {
    @apply bg-panel dark:bg-dark-panel;
  }

  // @apply dark:bg-dark-panel bg-red-500 rounded-lg;
  //   mat-dialog-container {
  //     @apply dark:bg-dark-panel bg-red-500 rounded-lg;
  // }
}