// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@include mat.core();

$app-yellow: (
  50: #ffd42f,
  100: #fff3cc,
  200: #ffecb3,
  300: #ffe699,
  400: #ffe080,
  500: #ffd966,
  600: #ffd42f,
  700: #ffce28,
  800: #ffc720,
  900: #ffbf18,
  A100: #ffffff,
  A200: #fff9e6,
  A400: #ffd42f,
  A700: #ffce28,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);


$OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-primary: mat.define-palette(mat.$indigo-palette);
$OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-accent: mat.define-palette($app-yellow,
    A200,
    A100,
    A400);
// $OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-accent: $custom-accent;

// The warn palette is optional (defaults to red).
$OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-warn: mat.define-palette(mat.$red-palette);

$OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-theme: mat.define-light-theme((color: (primary: $OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-primary,
        accent: $OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-accent,
        warn: $OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-warn,
      ),
    ));

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'assets/template/theme.scss';
@import '~flatpickr/dist/flatpickr.min.css';


.mdc-notched-outline__notch {
  border-right: none !important;
}



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($OSD-WARFIGHTER-TOUCHPOINT-FRONTEND-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  background-color: #fef6e4;
  min-height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body,
app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header-container,
app-header {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
}

.footer-container,
app-footer {
  flex-shrink: 0;
}

.svg-icon-sm {
  width: 16px;
  height: 16px;
}

.mat-toolbar-row-header,
.mat-toolbar-row-battery {
  background-color: #fff;
}

@media (display-mode: standalone) and (max-width: 768px) {
  .toolbar {
    padding-top: env(safe-area-inset-top, 0px) !important;
    background-color: var(--primary-color, #ffd42f) !important;
    height: fit-content !important;
    padding-bottom: 10px !important;
  }

  .mat-toolbar-row-header,
  .mat-toolbar-row-battery {
    background-color: #fff;
  }

  .footer {
    padding-bottom: 14px !important;
  }
}

.btn-primary {
  color: #6a5502 !important;
  background-color: #ffd42f !important;

  &:disabled {
    color: rgba(106, 85, 2, 0.5) !important;
    background-color: rgba(255, 212, 47, 0.5) !important;
  }
}

button[mat-raised-button].app-primary {
  background-color: #ffffff;
  color: #333;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #f2f2f2;
  }

  &:active {
    background-color: #e6e6e6;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
  }

  &:disabled {
    background-color: #ccc;
    color: #aaa;
  }
}

button[mat-raised-button].app-secondary {
  background-color: #333;
  color: #ffd42f;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #555;
  }

  &:active {
    background-color: #111;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 212, 47, 0.5);
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
  }
}

button[mat-raised-button].app-accent {
  background-color: #e6e6e6;
  color: black;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #8b8b8b;
  }

  &:active {
    background-color: #6e6e6e;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(169, 169, 169, 0.5);
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
  }
}

button[mat-raised-button].app-error {
  background-color: #ef5350;
  color: white;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #e57373;
  }

  &:active {
    background-color: #d32f2f;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 115, 115, 0.5);
  }

  &:disabled {
    background-color: #ef9a9a;
    color: #bcbcbc;
  }
}

button[mat-raised-button].app-success {
  background-color: #66bb6a;
  color: white;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #81c784;
  }

  &:active {
    background-color: #388e3c;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(129, 199, 132, 0.5);
  }

  &:disabled {
    background-color: #a5d6a7;
    color: #bcbcbc;
  }
}

button[mat-raised-button].app-neutral {
  background-color: #9e9e9e9e;
  color: white;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #757575;
  }

  &:active {
    background-color: #616161;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
  }

  &:disabled {
    background-color: #bdbdbd;
    color: #e0e0e0;
  }
}



button[mat-mini-fab].app-secondary {
  background-color: #333;
  color: #ffd42f;

  mat-icon {
    color: #ffd42f;
  }

  &:hover {
    background-color: #555;
  }

  &:active {
    background-color: #111;
    transform: scale(0.98);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 212, 47, 0.5);
  }

  &:disabled {
    background-color: #ccc;

    mat-icon {
      color: #666;
    }
  }
}


@media print {
  .content {
    width: 100% !important;
  }

  .header-container {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }

  .print-body * {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    page-break-inside: avoid;
  }

  .print-section {
    font-size: 12pt;

    h1 {
      font-size: 16pt;
    }

    img.print-img {
      max-width: 300px;
      max-height: 300px;
      page-break-inside: avoid;
    }
  }
}

.modal-container {
  width: 300px !important;
}



.app-error {
  background: #EF4444 !important;
}

.hot-toast-message {
  margin: 0px 0px 0px 0px !important;
  @apply rounded-md;
}

.hot-toast-close-btn {
  color: black;
  @apply mr-2 text-red-900;
}


.highlight {
  @apply bg-yellow-500 text-yellow-800;
}




@media print,
screen {
  table td {
    padding: 4px;
    min-height: 30px;
    font-size: 10px
  }

  table th {
    padding: 4px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
}

.embedded-pdf {
  width: 100%;
  height: 100%;
  border: none;
}

.sidebar-item {
  @apply px-3 py-2 rounded-lg transition-all duration-200 cursor-pointer relative;
  height: 40px;

  &.active {
    @apply bg-white/10;
  }

  &:hover {
    @apply bg-white/10;
  }
}

::ng-deep .sidebar-tooltip {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  font-size: 14px !important;
  margin: 0 !important;
  padding: 8px 16px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  position: fixed !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-weight: 500 !important;
  transform: translateX(52px) !important;
  box-shadow: none !important;
  white-space: nowrap !important;
}


::ng-deep .mat-mdc-tooltip-panel-class {
  .mdc-tooltip__surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}


::ng-deep .mat-mdc-tooltip {
  margin: 0 !important;

  &-panel {
    transform: none !important;
  }
}


::ng-deep .mat-mdc-tooltip-panel-showing {
  opacity: 1 !important;
}